import React, { useEffect } from "react"
import Qr from "../_utils/Qr"
import { goto } from "../_utils/Misc"
import Layout from "../_components/Layout/Layout"
import UserForm from "../_components/UserForm/UserForm"
import MainTitle from "../_components/MainTitle/MainTitle"
import PageSummary from "../_components/PageSummary/PageSummary"

const Registration = () => {
    useEffect(() => {
        if (!Qr.hasStoredParams()) {
            goto("/")
        }
    }, [])

    return (
        <Layout title="Registration">
            <MainTitle>Register <br/> your details</MainTitle>

            <PageSummary>
                <span className="text-center">
                    Sharing your details helps us deliver the best experience to you. Be sure to use a valid email to receive your gift!
                </span>
            </PageSummary>

            <UserForm showEmail={true} btnText="Unlock my gift" />

            {/* <StyledLink to="/login">Already registered? Click here</StyledLink> */}
        </Layout>
    )
}

export default Registration
