import React, { useState } from "react"
import "./styles.scss"
import validateForm from "../../_utils/ValidateForm"
import useForm from "../../_utils/UseForm"

const UserForm = ({ btnText, isAccountForm, values }) => {
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const {
        handleChange,
        handleSubmit,
        form,
        errors,
        updateForm,
    } = useForm(validateForm, isAccountForm, values)
    const [busy, setBusy] = useState(false)

    const {
        email,
        emailRepeat,
        fullName,
        favouriteCuisine,
        postcode,
        firstTasteExperience,
        dobYear,
        gender,
        preference_ethnicity,
        newsletterSignup
    } = isAccountForm ? updateForm : form

    const ethnicityOptions = [
        'Asian/Asian British',
        'Black/African/Caribbean/Black British',
        'White',
        'Other',
        'Prefer not to say'
    ]

    const favouriteCuisinesList = [
        "Argentinian",
        "British",
        "Caribbean",
        "Casual",
        "Chinese",
        "Dessert",
        "Indian",
        "Italian",
        "Filipino",
        "Japanese",
        "Mexican",
        "Peruvian",
        "Portuguese",
        "Seafood",
        "Spanish",
        "South East Asian",
        "Sri Lankan",
        "Turkish",
        "Vegan",
        "West African",
        "Vietnamese",
        "Thai",
        "French",
        "Nordic",
        "Moroccan/ Middle Eastern",
        "Greek"
    ]

    const genderList = [
        'Male',
        'Female',
        'Non-binary/ third gender',
        'A gender not listed',
        'I use another term',
        'Prefer not to say '
    ]

    const getAge = () => {
        let currentYear = new Date().getFullYear()
        return currentYear - dobYear
    }

    const submitForm = (e) => {
        setBusy(true)

        handleSubmit(e).then(response => {
            if (!!response && response.message_message) {
                setErrorMessage(response.message_message)
            }

            if (!!response && response.success_message) {
                setSuccessMessage(response.success_message)
            }

            setBusy(false)
        }, (err) => {
            if (!!err && err.message_message) {
                setErrorMessage(err.message_message)
            } else {
                setErrorMessage('We cannot process your request now. Please try again later.')
            }

            setBusy(false)
        })
    }

    return (
        <form className="registration-form" onSubmit={(e) => submitForm(e)}>
            <div className="mb-3">
                <label htmlFor="f-email" className="form-label required">Email</label>
                <input
                    type="email"
                    name="email"
                    id="f-email"
                    className="form-control"
                    value={email}
                    readOnly={isAccountForm}
                    onChange={handleChange}
                    required={!isAccountForm}
                />
            </div>

            {!isAccountForm ? (
                <div className="mb-3">
                    <label htmlFor="f-emailRepeat" className="form-label required">Re-Enter Email</label>
                    <input
                        type="email"
                        name="emailRepeat"
                        id="f-emailRepeat"
                        className="form-control"
                        value={emailRepeat}
                        onChange={handleChange}
                        required={true}
                    />
                </div>
            ) : null}

            <div className="mb-3">
                <label htmlFor="f-fullName" className="form-label required">Full Name</label>
                <input
                    type="text"
                    name="fullName"
                    id="f-fullName"
                    className="form-control"
                    value={fullName}
                    onChange={handleChange}
                    required={true}
                />
            </div>

            <div className="form-group mb-3">
                <label htmlFor="f-dobYear" className="form-label required">Birth year</label>
                <select
                    className="form-select"
                    onChange={handleChange}
                    name="dobYear"
                    id="f-dobYear"
                    value={dobYear}
                    required={true}
                >
                    <option disabled value=""></option>
                    {[...Array(100)].map((x, i) =>
                        <option
                            key={`dob-y-${i}`}
                            value={2024 - i}
                        >
                            {2024 - i}
                        </option>,
                    )}
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="f-postcode" className="form-label required">Postcode</label>
                <input
                    type="text"
                    name="postcode"
                    id="f-postcode"
                    className="form-control"
                    value={postcode}
                    onChange={handleChange}
                    required={true}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="f-gender" className="form-label required">Gender</label>
                <select
                    className="form-select"
                    onChange={handleChange}
                    name="gender"
                    id="f-gender"
                    required
                    value={gender}
                >
                    <option disabled value=""></option>
                    {genderList.map((name, key) =>
                        <option key={`favouriteCuisine-${key}`} value={name}>{name}</option>,
                    )}
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="f-favouriteCuisine" className="form-label">Food I Love</label>

                <select
                    className="form-select"
                    onChange={handleChange}
                    name="favouriteCuisine"
                    id="f-favouriteCuisine"
                    value={favouriteCuisine}
                >
                    <option disabled value=""></option>
                    {favouriteCuisinesList.map((name, key) =>
                        <option key={`favouriteCuisine-${key}`} value={name}>{name}</option>,
                    )}
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="f-firstTasteExperience" className="form-label">Your First Taste Experience?</label>
                <select
                    className="form-select"
                    onChange={handleChange}
                    name="firstTasteExperience"
                    id="f-firstTasteExperience"
                    value={firstTasteExperience}
                >
                    <option disabled value=""></option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="f-preference_ethnicity" className="form-label">Ethnicity (optional)</label>
                <select
                    className="form-select"
                    onChange={handleChange}
                    name="preference_ethnicity"
                    id="f-preference_ethnicity"
                    value={preference_ethnicity}
                >
                    <option disabled value=""></option>
                    {ethnicityOptions.map((name, key) =>
                        <option key={`preference_ethnicity-${key}`} value={name}>{name}</option>,
                    )}
                </select>
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="checkbox"
                    name="newsletterSignup"
                    checked={!!newsletterSignup}
                    id="f-newsletterSignup"
                    onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="f-newsletterSignup">
                    Sign me up to receive gifts and offers like this from Taste Festivals Limited and shared affiliates.
                </label>
            </div>

            {!isAccountForm && (
                <>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="f-terms" required={true} />
                        <label className="form-check-label" htmlFor="f-terms">
                            <span className="required">
                                I agree to the <a href="/terms/" target="_blank" rel="noreferrer">Terms & Conditions</a>.
                            </span>
                            <span> By signing up Taste Festivals Limited and International Management Group (UK) Limited are the controllers of your data.
                                Click Unsubscribe in any email to withdraw your consent or change your preferences as described in our <a href="/privacy/" target="_blank" rel="noreferrer">Privacy Policy</a>
                            </span>
                        </label>
                    </div>
                </>
            )}

            {Object.keys(errors).map((errorName, index) => {
                if (errors[errorName] !== null) {
                    return (
                        <div className="form-text text-danger error-message" key={index}>
                            {errors[errorName]}
                        </div>
                    )
                }
                return null
            })}

            {!!errorMessage && (
                <p className="error-message">{errorMessage}</p>
            )}

            {!!successMessage && (
                <p className="success-message">{successMessage}</p>
            )}

            {getAge() < 18 && (
                <p className="text-danger">You must be over the age of 18 to participate in this giveaway.</p>
            )}

            <div className="d-flex justify-content-center align-items-center">
                <button
                    type="submit"
                    className="btn btn-form"
                    disabled={getAge() < 18 || busy}
                >
                    {btnText}
                </button>
            </div>
        </form>
    )
}

export default UserForm
